import { Transition } from '@headlessui/react'
import {
  useRef,
  type ComponentPropsWithoutRef,
  type Dispatch,
  type SetStateAction,
  useEffect,
  useCallback,
} from 'react'
import FocusTrap from 'focus-trap-react'
import Backdrop from '@components/backdrop'
import type { Options as FocusTrapOptions } from 'focus-trap'
import { Button, Icon } from '@shc/ui'

const focusTrapOptions: FocusTrapOptions = {
  checkCanFocusTrap: (trapContainers: Element[]): any => {
    const results = trapContainers.map((trapContainer: Element) => {
      return new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (getComputedStyle(trapContainer).visibility !== 'hidden') {
            resolve()
            clearInterval(interval)
          }
        }, 3)
      })
    })
    // Return a promise that resolves when all the trap containers are able to receive focus
    return Promise.all(results)
  },
  fallbackFocus: 'body',
}

export interface DrawerProps extends ComponentPropsWithoutRef<'nav'> {
  isDrawerOpen: boolean
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
  activePathname: string
  label?: string
  labelledBy?: string
  eventPrefix?: string
}

const Drawer = ({
  children,
  isDrawerOpen,
  setIsDrawerOpen,
  activePathname,
  label = 'Drawer',
  labelledBy,
  eventPrefix = 'drawer',
  ...props
}: DrawerProps) => {
  // const [navSlideIdx, setNavSlideIdx] = useState<number>(-1)
  const containerRef = useRef<HTMLDivElement>(null)
  const closeButtonRef = useRef<HTMLButtonElement>(null)

  const close = useCallback(() => {
    setIsDrawerOpen(false)
    // setNavSlideIdx(-1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsDrawerOpen])

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (isDrawerOpen && event.key === 'Escape') {
        close()
      }
    },
    [isDrawerOpen, close]
  )

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const notInContainer =
        containerRef.current && !containerRef.current.contains(event.target as Node)
      const notInCloseButton =
        closeButtonRef.current && !closeButtonRef.current.contains(event.target as Node)
      if (isDrawerOpen && notInContainer && notInCloseButton) {
        close()
      }
    },
    [isDrawerOpen, containerRef, closeButtonRef, close]
  )

  // Close on esc key
  useEffect(() => {
    document.addEventListener('keydown', escFunction, true)
    return () => {
      document.removeEventListener('keydown', escFunction, true)
    }
  }, [escFunction])

  // Close on click outside menu
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside, containerRef, closeButtonRef])

  // Disabled body scroll when open
  useEffect(() => {
    const hasVScrollbar = window.innerWidth - document.documentElement.clientWidth > 0
    const vScrollbarWidth = window.innerWidth - document.documentElement.clientWidth
    if (isDrawerOpen) {
      document.documentElement.style.overflow = 'hidden'
      if (hasVScrollbar) {
        document.documentElement.style.paddingRight = `${vScrollbarWidth}px`
      }
    } else {
      document.documentElement.style.removeProperty('overflow')
      document.documentElement.style.removeProperty('padding-right')
    }
  }, [isDrawerOpen])

  return (
    <>
      <FocusTrap active={isDrawerOpen} focusTrapOptions={focusTrapOptions}>
        <Transition
          unmount={false} // Do not remove from DOM (for screen readers and performance)
          className="fixed top-0 left-0 z-30 w-full h-full transition-all origin-left scale"
          show={isDrawerOpen}
          enter="!block transition ease-out duration-300 delay-100"
          enterFrom="opacity-0 -translate-x-96"
          enterTo="opacity-100 translate-x-0"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 -translate-x-96"
          as="nav"
          aria-labelledby={labelledBy}
          aria-label={label}
          {...props}>
          <Button
            aria-label={'Close'}
            color="transparent"
            shape="circle"
            width="auto"
            size="sm"
            className="m-3 text-gray-700 transition-all transform !absolute top-0 left-[calc(100%_-_4rem)] sm:left-96 z-30"
            onClick={close}
            ref={closeButtonRef}>
            <Icon icon="xmark-large" className="h-5 text-white" />
          </Button>
          <div ref={containerRef} className="flex flex-col h-full w-[calc(100%_-_4rem)] sm:w-96">
            <div className="flex flex-col flex-grow w-full pb-8 overflow-x-hidden overflow-y-auto bg-white sm:w-96">
              <div className="px-5 pb-5 pt-6">{children}</div>
            </div>
          </div>
        </Transition>
      </FocusTrap>

      <Transition
        className="fixed inset-0 z-20 block"
        as={Backdrop}
        show={isDrawerOpen}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-200 delay-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      />
    </>
  )
}

export default Drawer
