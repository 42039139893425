'use client'

import { useCurrentRefinements, useClearRefinements } from 'react-instantsearch'
import SearchRefinementList, {
  type SearchRefinementListProps,
} from '@components/search-refinement-list'
import type { CurrentRefinementsConnectorParamsRefinement } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements'
import { hierarchicalDelimiter } from '@lib/search'
import { Button, Icon, Link, Typography } from '@shc/ui'

export interface SearchFacetMenuProps {
  readonly facets: string[]
  readonly facetConfigMap: Map<string, Pick<SearchRefinementListProps, 'title' | 'operator'>>
  readonly refinementListLimit?: number
}

export default function SearchFacetMenu({
  facets,
  facetConfigMap,
  refinementListLimit = 6,
}: SearchFacetMenuProps) {
  //get the facets that have been selected
  const { items, refine: refineFacets } = useCurrentRefinements()
  //clear all function
  const { refine } = useClearRefinements()

  const clearAllClick = () => {
    refine()
  }

  const clearRefinement = (
    refinement: CurrentRefinementsConnectorParamsRefinement,
    refinements: CurrentRefinementsConnectorParamsRefinement[]
  ) => {
    refineFacets(refinement)
    // loop over other refinements in this facet and deselect children in case of hierarchy
    refinements?.forEach((otherRefinement) => {
      if (
        typeof otherRefinement.value === 'string' &&
        otherRefinement.value.startsWith(refinement.value.toString())
      )
        refineFacets(otherRefinement)
    })
  }

  return (
    <div className="flex flex-col">
      {/* current filters */}
      {items.length > 0 && (
        <>
          <div className="flex flex-row text-sm justify-between">
            <Typography variant="h5">Filtered by:</Typography>

            <Link as="button" color="dark" onClick={() => clearAllClick()}>
              Clear all filters
            </Link>
          </div>

          <div className="py-5 border-b mb-5 gap-x-2 gap-y-1 flex flex-row flex-wrap -ml-2">
            {items.map((item) =>
              item.refinements.map((refinement) => {
                const labelParts = refinement.label.split(hierarchicalDelimiter)
                return (
                  <Button
                    key={refinement.value}
                    className="!h-auto !text-left !inline !p-2"
                    onClick={() => clearRefinement(refinement, item.refinements)}
                    size="xs"
                    width="auto"
                    color="transparent">
                    {labelParts[labelParts.length - 1]}
                    <Icon className="h-3 text-center pl-2" icon="xmark" />
                  </Button>
                )
              })
            )}
          </div>
        </>
      )}

      {/* selectable facets */}
      <div className="-mt-5">
        {facets.map((facet) => (
          <div key={facet} className="flex flex-col">
            <SearchRefinementList
              title={facetConfigMap.get(facet)?.title ?? facet}
              attribute={facet}
              showMore={true}
              showMoreLimit={Infinity}
              limit={refinementListLimit}
              operator={facetConfigMap.get(facet)?.operator}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
