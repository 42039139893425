'use client'

import type { UseRefinementListProps } from 'react-instantsearch'
import { useCurrentRefinements, useRefinementList } from 'react-instantsearch'
import React, { useState } from 'react'
import { Link, Typography } from '@shc/ui'
import type { SearchRefinementListItemProps } from '@components/search-refinement-list-item'
import SearchRefinementListItem from '@components/search-refinement-list-item'
import type { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList'
import Accordion from '@components/accordion'
import { hierarchicalDelimiter } from '@lib/search'
import useCollapse from 'react-collapsed'
import useScreenMediaQuery from '@hooks/useScreenMediaQuery'

export const transformItems = (items: RefinementListItem[]): RefinementListItem[] => {
  // the resulting tree
  const rootItems = []

  // cache of previously processed items
  const lookup = new Map()

  // delimiter for algolia hierarchy
  const delim = hierarchicalDelimiter

  // loop over items
  for (const item of items) {
    const levels = item.value.split(delim)
    const parentId = levels[levels.length - 2]
    const itemId = item.value

    // fix label to only show last level
    item.label = levels[levels.length - 1]

    // check lookup
    if (!lookup.has(itemId)) {
      // item not found, add placeholder data for now
      lookup.set(itemId, { data: [] })
    }

    // update lookup with current item data
    lookup.set(itemId, { ...item, data: lookup.get(itemId).data })

    const treeItemPointer = lookup.get(itemId)

    // if root item
    if (typeof parentId === 'undefined') {
      rootItems.push(treeItemPointer)
    }
    // else nested item
    else {
      // check lookup for parent
      if (!lookup.has(parentId)) {
        // parent not found, add placeholder data for now
        lookup.set(parentId, { data: [] })
      }

      // add the current item to the parent
      lookup.get(parentId).data.push(treeItemPointer)
    }
  }

  return rootItems
}

// TODO: handle searchable: isFromSearch, searchForItems, hasExhaustiveItems

export interface SearchRefinementListProps extends UseRefinementListProps {
  title: string
}

const SearchRefinementList = ({ title, ...props }: SearchRefinementListProps) => {
  const { items, refine, canRefine, sendEvent } = useRefinementList({
    ...props,
    showMore: false, // We have to handle showMore logic here because of possible hierarchy
    limit: Infinity,
    transformItems,
  })

  const isDesktop = useScreenMediaQuery('xl')

  const currentRefinements = useCurrentRefinements()
  const [isShowingMore, setIsShowingMore] = useState(false)
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded: isShowingMore })
  const limit = props.limit ?? 6
  const canShowMore = items.length > limit
  const limitedItems = canShowMore ? items.slice(0, limit) : items
  const moreItems = canShowMore ? items.slice(limit) : []
  const onChangeHandler = (
    value: string,
    hasChildRefined: boolean,
    data?: SearchRefinementListItemProps[]
  ): void => {
    sendEvent('click', value)
    // if it has a child refinement then this refinement is not actually refined so do not refine
    if (!hasChildRefined) refine(value)
    // find other refinements for this facet
    const otherRefinements =
      currentRefinements.items.find(({ attribute }) => attribute === props.attribute)
        ?.refinements ?? []

    // for each other refinement look for a parent and unrefine it
    otherRefinements.forEach((otherRefinement) => {
      const refinementParts = value.toString().split(hierarchicalDelimiter)
      if (
        typeof otherRefinement.value === 'string' &&
        otherRefinement.value ===
          refinementParts.slice(0, refinementParts.length - 1).join(hierarchicalDelimiter)
      )
        currentRefinements.refine(otherRefinement)
    })

    // deselect children refinements
    data?.forEach((item) => {
      if (item.isRefined) refine(item.value)
    })
  }

  const toggleAccordion = () => {
    setIsShowingMore((isShowingMore) => !isShowingMore)
  }

  const itemMapper = (item: RefinementListItem) => (
    <SearchRefinementListItem {...item} key={item.value} onChange={onChangeHandler} />
  )

  if (!canRefine) return null

  if (isDesktop) {
    return (
      <fieldset className="border-b pb-5">
        <legend className="pt-5 pb-3">
          <Typography variant="body-semibold">{title}</Typography>
        </legend>
        <div className="[&>*:first-child]:pt-0">
          {limitedItems.map(itemMapper)}
          <div {...getCollapseProps()}>{moreItems.map(itemMapper)}</div>
          {canShowMore && (
            <Link
              as="button"
              color="primary"
              noUnderline
              className="text-sm mt-3"
              {...getToggleProps({ onClick: toggleAccordion })}>
              See {isShowingMore ? 'less' : 'more'}
            </Link>
          )}
        </div>
      </fieldset>
    )
  }

  return (
    <Accordion title={title}>
      <div className="[&>*:first-child]:pt-0">{items.map(itemMapper)}</div>
    </Accordion>
  )
}

export default SearchRefinementList
